<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          Riwayat Transaksi
          <div class="card-header-actions"></div>
        </CCardHeader>
        <CCardBody>
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :pagination-options="{
              enabled: true,
              perPageDropdown: [10, 20, 30, 50, 100],
              dropdownAllowAll: false,
            }"
            :rows="rows"
            :columns="columns"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'created_at'">
                <span>
                  {{ timestampModify(props.row.created_at) }}
                  <br />
                  <strong>
                    {{
                      getDuration(props.row.created_at, props.row.updated_at)
                    }}
                  </strong>
                </span>
              </span>
              <span v-else-if="props.column.field == 'serial'">
                <span>
                  <small>
                    {{ props.row.sku }}
                  </small>
                  <br />
                  {{ props.row.serial }}
                </span>
              </span>
              <span v-else-if="props.column.field == 'status'">
                <span>
                  <CBadge
                    v-if="props.row.status_text == 'success'"
                    color="success"
                  >
                    Sukses
                  </CBadge>
                  <CBadge
                    v-if="props.row.status_text == 'pending'"
                    color="warning"
                  >
                    Pending
                  </CBadge>
                  <CBadge
                    v-if="props.row.status_text == 'failed'"
                    color="danger"
                  >
                    Gagal
                  </CBadge>
                </span>
              </span>
            </template>
          </vue-good-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import axios from "../../apis/api";

import moment from "moment";
import "moment/locale/id";

import pusher from "../../apis/pusher";

export default {
  name: "Breadcrumbs",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "No",
          field: "index",
          sortable: false,
        },
        {
          label: "Tanggal",
          field: "created_at",
        },
        {
          label: "No Pelanggan",
          field: "customer_no",
          sortable: false,
        },
        {
          label: "No Seri / Voucher",
          field: "serial",
          sortable: false,
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Nominal",
          field: "amount",
        },
      ],
      totalRecords: 0,
    };
  },
  computed: {
    rows() {
      return this.$store.state.trx_list;
    },
    serverParams() {
      return this.$store.state.trx_list_param;
    },
  },
  methods: {
    getDuration(st, ed) {
      let start = moment(st);
      let end = moment(ed);
      let diff = end.diff(start);
      let f = moment.utc(diff).format("mm:ss");
      return f;
    },
    timestampModify(timestamp) {
      return moment(timestamp).format("DD-MM-YYYY HH:mm:ss");
    },
    updateParams(newProps) {
      let serverParams = Object.assign({}, this.serverParams, newProps);
      this.$store.commit("set", ["trx_list_param", serverParams]);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async firstLoad() {
      this.updateParams({
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        perPage: 10,
      });
      await this.loadItems();
    },

    async loadItems() {
      try {
        let response = await axios.get("/transaction", {
          params: this.$store.state.trx_list_param,
        });
        this.totalRecords = response.data.data.meta.total;
        let list = response.data.data.values;
        list.forEach((item, index) => {
          item.index = index + response.data.data.meta.first_item;
        });

        this.$store.commit("set", ["trx_list", response.data.data.values]);
      } catch (error) {
        if (typeof error.response.data.message !== "undefined") {
          await this.$notify({
            type: "error",
            text: error.response.data.message,
          });
        }
        this.totalRecords = 0;
        this.$store.commit("set", ["trx_list", []]);
      }
    },
  },
  async created() {
    await this.firstLoad();
  },
};
</script>
